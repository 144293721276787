(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('NavigationMenu', [], factory);
    } else {
        // Browser globals
        root.NavigationMenu = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function NavigationMenu(element, options) {
        this.$el = element;
        this.$refs = {
            button: this.$el.querySelector('.menutoggle'),
            overlay: this.$el.querySelector('[data-overlay]')
        }

        if (!this.$el || !this.$refs.button) return;

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onopen = this.onopen.bind(this);
        this.onclose = this.onclose.bind(this);
        this.dispatch = this.dispatch.bind(this);
        this.overlay = this.overlay.bind(this)
        this.hide = this.hide.bind(this)
        this.show = this.show.bind(this)

        this.$refs.button.addEventListener('click', this.toggle);

        document.addEventListener('click', this.close);

        this.$el.addEventListener('click', function (event) {
            event.stopPropagation();
        })

        this.$refs.animated = this.$el.querySelectorAll('[data-animation]');

        Array.prototype.forEach.apply(this.$refs.animated, [function (el) {
            el.style.display = 'none'
        }])
    }

    NavigationMenu.prototype = {
        close: function () {
            if (!this.$el.classList.contains('-open')) return;
            this.$el.classList.remove('-open');
            this.$refs.button.classList.remove('-open');

            Array.prototype.forEach.apply(this.$refs.animated, [function ($el) {
                var animationName = $el.dataset.animation || 'v'
                $el.addEventListener('animationend', function (event) {
                    event.stopPropagation();
                    event.target.classList.remove(animationName + '-enter')
                    event.target.classList.remove(animationName + '-leave')
                    event.target.classList.remove(animationName + '-enter-active')
                    event.target.classList.remove(animationName + '-leave-active')
                    if (this.$el.classList.contains('-open') == false) {
                        event.target.style.display = 'none'
                    }
                }.bind(this), { once: true })
                $el.classList.add(animationName + '-leave')
                $el.classList.add(animationName + '-leave-active')
            }.bind(this)])

            if (typeof (this.__onclose) === 'function') {
                this.__onclose.apply(this);
            }

            this.dispatch('navigation-menu:close', {
                bubbles: true,
                cancelable: false,
                detail: {
                    component: this
                }
            });
        },
        open: function () {
            this.$el.classList.add('-open');
            this.$refs.button.classList.add('-open');

            Array.prototype.forEach.apply(this.$refs.animated, [function ($el) {
                var animationName = $el.dataset.animation || 'v'
                $el.style.display = null
                $el.addEventListener('animationend', function (event) {
                    event.stopPropagation();
                    event.target.classList.remove(animationName + '-enter')
                    event.target.classList.remove(animationName + '-leave')
                    event.target.classList.remove(animationName + '-enter-active')
                    event.target.classList.remove(animationName + '-leave-active')
                }.bind(this), { once: true })
                $el.classList.add(animationName + '-enter')
                $el.classList.add(animationName + '-enter-active')
            }.bind(this)])

            if (typeof (this.__onopen) === 'function') {
                this.__onopen.apply(this);
            }

            this.dispatch('navigation-menu:open', {
                bubbles: true,
                cancelable: false,
                detail: {
                    component: this
                }
            });
        },
        toggle: function (event) {
            event.stopPropagation();

            if (this.$el.classList.contains('-open')) {
                this.close()
            } else {
                this.open()
            }
        },
        onopen: function (fn) {
            if (typeof (fn) !== 'function') return;

            this.__onopen = fn.bind(this);
        },
        onclose: function (fn) {
            if (typeof (fn) !== 'function') return;

            this.__onclose = fn.bind(this);
        },
        overlay: function (show) {
            if (show) {
                if (this.$refs.overlay.classList.contains('-visible')) {
                    return
                }

                this.$refs.overlay.classList.add('-visible')
            } else {
                if (this.$refs.overlay.classList.contains('-visible')) {
                    this.$refs.overlay.classList.remove('-visible')
                }
            }
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.$el.dispatchEvent(newEvent);
        },
        hide: function () {
            if (this.$el.classList.contains('-hidden')) {
                return
            }

            this.$el.classList.add('-hidden')
        },
        show: function () {
            if (this.$el.classList.contains('-hidden')) {
                this.$el.classList.remove('-hidden')
            }
        }
    }

    return NavigationMenu

}));
