(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('GradientBackground', [], factory);
    } else {
        // Browser globals
        root.GradientBackground = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function GradientBackground(element) {
        this.$el = element
        this.$refs = {
            stops: Array.from(this.$el.querySelectorAll('stop'))
        }
    }

    function animateColor(element, to) {
        var from = element.getAttribute('stop-color')

        if (from == to) {
            return
        }

        var prevAnim = element.querySelectorAll('animate')
        if (prevAnim.length) {
            Array.prototype.forEach.apply(prevAnim, [function (el) {
                el.remove()
            }])
        }

        var animation = document.createElementNS('http://www.w3.org/2000/svg', 'animate')
        animation.setAttribute('attributeName', 'stop-color')
        animation.setAttribute('fill', 'freeze')
        animation.setAttribute('dur', '500ms')
        animation.setAttribute('values', from + ';' + to)

        animation.addEventListener('endEvent', function (event) {
            event.target.parentElement.setAttribute('stop-color', to)
        })

        element.appendChild(animation)
        animation.beginElement()
    }

    GradientBackground.prototype = {
        update: function (start, stop) {
            start = start || 'transparent'
            stop = stop || 'transparent'

            animateColor(this.$refs.stops[0], start)
            animateColor(this.$refs.stops[1], stop)

        }
    }

    return GradientBackground
}));
