(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('DefaultLoader', [], factory);
    } else {
        // Browser globals
        root.DefaultLoader = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function DefaultLoader(element) {
        this.$el = element
        this.$refs = {}
        this.$refs.svg = this.$el.querySelector('svg')
    }

    DefaultLoader.prototype = {
        show: function () {
            this.$el.addEventListener('animationend', function (event) {
                event.stopPropagation()
                this.$el.classList.remove('background-animate-enter')
                this.$el.classList.remove('background-animate-leave')
            }.bind(this), { once: true })

            this.$refs.svg.addEventListener('animationend', function (event) {
                event.stopPropagation()
                this.$refs.svg.classList.remove('logo-animate-enter')
                this.$refs.svg.classList.remove('logo-animate-leave')
            }.bind(this), { once: true })

            this.$el.style.display = null
            this.$el.classList.add('background-amimate-enter')
            this.$refs.svg.style.display = null
            this.$refs.svg.classList.add('logo-animate-enter')
        },
        hide: function () {
            this.$el.addEventListener('animationend', function (event) {
                event.stopPropagation()
                this.$el.classList.remove('background-animate-enter')
                this.$el.classList.remove('background-animate-leave')
                event.target.style.display = 'none'
            }.bind(this), { once: true })

            this.$refs.svg.addEventListener('animationend', function (event) {
                event.stopPropagation()
                this.$refs.svg.classList.remove('logo-animate-enter')
                this.$refs.svg.classList.remove('logo-animate-leave')
                event.target.style.display = 'none'
            }.bind(this), { once: true })


            this.$el.classList.add('background-animate-leave')
            this.$refs.svg.classList.add('logo-animate-leave')
        }
    }

    return DefaultLoader
}));
