(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('WowzaVideoPlayer', ['WowzaPlayer'], factory);
    } else {
        // Browser globals
        root.WowzaVideoPlayer = factory(root.WowzaPlayer);
    }
}(typeof self !== 'undefined' ? self : this, function (WowzaPlayer) {
    'use strict';

    function Player(element) {
        this.$el = element
        this.license = this.$el.dataset.license
        this.source = this.$el.dataset.source
        this.poster = this.$el.dataset.poster

        this.$el.style['background-image'] = 'url(' + this.poster + ')'

        if (this.source == false) {
            return
        }

        this.dispatch = this.dispatch.bind(this)
        this.handlePlay = this.handlePlay.bind(this)
        this.handlePause = this.handlePause.bind(this)
        this.handleCompleted = this.handleCompleted.bind(this)

        this.player = WowzaPlayer.create(this.$el.id, {
            license: this.license,
            sources: [{ sourceURL: this.source }],
            autoPlay: false,
            mute: true,
            volume: 75,
            uiShowPlaybackControls: true,
            uiShowQuickRewind: false,
            uiShowSeekBar: true,
            uiShowVolumeControl: true,
            uiShowDurationVsTimeRemaining: false,
            uiShowBitrateSelector: false,
            uiPosterFrameFillMode: 'fill',
            posterFrameURL: this.poster,
            abrStartingBitrate: 'highest',
            abrAutoSwitching: true,
            debugLevel: 'WARN'
        })

        this.player.onPlay(this.handlePlay)
        this.player.onResume(this.handlePlay)
        this.player.onPause(this.handlePause)
        this.player.onCompleted(this.handleCompleted)

        this.$el.addEventListener('contextmenu', function (event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        });
    }

    Player.prototype = {
        handlePlay: function (event) {
            this.dispatch('wowza-video-player:play', {
                bubbles: true,
                cancelable: false,
                detail: {
                    instance: this,
                    player: event.player,
                }
            })
        },
        handlePause: function (event) {
            this.dispatch('wowza-video-player:pause', {
                bubbles: true,
                cancelable: false,
                detail: {
                    instance: this,
                    player: event.player,
                }
            })
        },
        handleCompleted: function (event) {
            this.dispatch('wowza-video-player:completed', {
                bubbles: true,
                cancelable: false,
                detail: {
                    instance: this,
                    player: event.player,
                }
            })
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.$el.dispatchEvent(newEvent);
        },
    }

    return Player

}));