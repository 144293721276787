(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('HomepageSlideshow', ['AnimatedSlide'], factory);
    } else {
        // Browser globals
        root.HomepageSlideshow = factory(root.AnimatedSlide);
    }
}(typeof self !== 'undefined' ? self : this, function (AnimatedSlide) {
    'use strict';

    function HomepageSlideshow(element) {
        this.$el = element
        this.$refs = {
            slides: Array.from(this.$el.querySelectorAll('[data-slide]'))
        }

        this.current = 0;

        this.animations = []

        this.goto = this.goto.bind(this)
        this.play = this.play.bind(this)
        this.stop = this.stop.bind(this)

        this.$refs.slides[this.current].addEventListener('animated-slide:ready', function (event) {
            this.play()
        }.bind(this))

        this.$refs.slides.forEach(function ($slide, index) {
            this.animations.push(new AnimatedSlide($slide))
            $slide.style.display = 'none'
            $slide.addEventListener('animated-slide:done', function () {
                this.$refs.slides[index].style.display = 'none'
                this.goto(index + 1)
            }.bind(this))
        }.bind(this))
    }

    HomepageSlideshow.prototype = {
        goto: function (index) {
            this.current = parseInt(index)
            if (isNaN(this.current) || this.current == this.animations.length ) {
                this.current = 0
            }

            this.current = Math.min(Math.max(this.current, 0), this.animations.length)

            this.$refs.slides[this.current].style.display = null
            this.animations[this.current].enter()
        },
        pause: function () {
            this.animations[this.current].animation.pause()
        },
        play: function () {
            this.$refs.slides[this.current].style.display = null
            this.animations[this.current].enter()
        },
        stop: function () {
            this.animations[this.current].reset()
        },
        hide: function () {
            if (parseInt(this.$el.style.opacity) == 0) {
                return
            }

            this.pause()
            this.$el.addEventListener('animationend', function (event) {
                event.stopPropagation();
                event.target.style.opacity = 0
                event.target.classList.remove('slide-off-enter')
                event.target.classList.remove('slide-off-leave')
                this.stop()
            }.bind(this))

            this.$el.classList.add('slide-off-leave')
        },
        show: function () {
            if (parseInt(this.$el.style.opacity) == 1) {
                return
            }

            this.$el.addEventListener('animationend', function (event) {
                event.stopPropagation();
                event.target.style.opacity = 1
                event.target.classList.remove('slide-off-enter')
                event.target.classList.remove('slide-off-leave')
                this.play()
            }.bind(this))

            this.$el.classList.add('slide-off-enter')
        }
    }

    return HomepageSlideshow

}));