(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ScrollContext', [
            'ScrollMagic',
            'AnimatedLogo',
            'DefaultLoader',
            'GradientBackground',
            'HomepageSlideshow',
            'IdeaCover',
            'NavigationMenu',
            'ScrollHint',
            'WowzaVideoPlayer',
        ], factory);
    } else {
        // Browser globals
        root.ScrollContext = factory(
            root.ScrollMagic,
            root.AnimatedLogo,
            root.DefaultLoader,
            root.GradientBackground,
            root.HomepageSlideshow,
            root.IdeaCover,
            root.NavigationMenu,
            root.ScrollHint,
            root.WowzaVideoPlayer
        );
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function ScrollContext(element, options) {
        // elemento DOM che definisce il contesto di questo componente
        this.$el = element

        // elementi DOM che fungono da contesto per sub-componenti o da elementi di questo
        // componente
        this.$refs = {
            // sfondo fisso con sfumatura dall'angolo in alto a sinistra all'angolo
            // in basso a destra
            background: this.$el.querySelector('[data-ui-component="gradient-background"]'),
            // contenuto scrollabile del componente
            content: this.$el.querySelector('[data-content]'),
            // elementi che modificano lo sfondo con sfumatura onscroll
            gradientTriggers: this.$el.querySelectorAll('[data-gradient]'),
            // animazione che suggerisce lo scroll
            hint: this.$el.querySelector('[data-ui-component="scroll-hint"]'),
            // loader animato
            loader: this.$el.querySelector('[data-ui-component="default-loader"]'),
            // logo animato in alto a sinistra
            logo: this.$el.querySelector('[data-ui-component="animated-logo"]'),
            // container del menu di navigazione a comparsa
            menu: this.$el.querySelector('[data-ui-component="navigation-menu"]'),
            // elementi che modificano il colore del menu di navigazione onscroll
            overlayTriggers: this.$el.querySelectorAll('[data-request-overlay]'),
            // contenitore dello slideshow con animazioni SVG in homepage
            slideshow: this.$el.querySelector('[data-ui-component="homepage-slideshow"]'),
            // icone social
            socialIcons: this.$el.querySelectorAll('[data-ui-component="navigation-menu"] > .social > li > a'),
            // contenitori dei player video Wowza nelle pagine di dettaglio
            wowzaPlayers: this.$el.querySelectorAll('[data-ui-component="wowza-video-player"]'),
        }

        this.MIN_WIDTH_EXPAND_LOGO = options && isNaN(parseInt(options.MIN_WIDTH_EXPAND_LOGO)) ? 960 : parseInt(options.MIN_WIDTH_EXPAND_LOGO)
        this.SCROLL_THRESHOLD = options && isNaN(parseInt(options.SCROLL_THRESHOLD)) ? 50 : parseInt(options.SCROLL_THRESHOLD)

        // collision detection degli elementi onscroll con https://scrollmagic.io/
        this.scroll = new ScrollMagic.Controller({
            container: this.$refs.content
        })

        // this#handleScroll funziona da proxy per creare nuove "scenes" ScrollMagic,
        // che vengono referenziate qui
        // Non utilizziamo la funzione tween di ScrollMagic, ci interessa solo utilizzare
        // i trigger di entrata e uscita degli elementi
        this.scenes = {}

        // creazione sub-componenti
        // per il comportamento di ciascun componente, vedi il file corrispondente
        this.components = {
            // sfondo fisso con sfumatura dall'angolo in alto a sinistra all'angolo
            // in basso a destra
            background: new GradientBackground(this.$refs.background),
            // animazione che suggerisce lo scroll
            hint: new ScrollHint(this.$refs.hint),
            // loader animato
            loader: new DefaultLoader(this.$refs.loader),
            // logo animato in alto a sinistra
            logo: new AnimatedLogo(this.$refs.logo),
            // menu di navigazione a comparsa
            menu: new NavigationMenu(this.$refs.menu),
            // slideshow con animazioni SVG in homepage
            slideshow: this.$refs.slideshow ? new HomepageSlideshow(this.$refs.slideshow) : null,
            // player video Wowza nelle pagine di dettaglio
            wowzaPlayers: Array.prototype.map.apply(this.$refs.wowzaPlayers, [function ($el) {
                return new WowzaVideoPlayer($el)
            }])
        }

        // binding dei metodi del componente, necessario a mantenere consistente
        // il valore di this all'interno dei metodi
        this.handleLoad = this.handleLoad.bind(this)
        this.handleMenuClose = this.handleMenuClose.bind(this)
        this.handleMenuOpen = this.handleMenuOpen.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.handleUnload = this.handleUnload.bind(this)
        this.loadComponent = this.loadComponent.bind(this)
        this.registerGradients = this.registerGradients.bind(this)
        this.registerPlayers = this.registerPlayers.bind(this)
        this.registerOverlays = this.registerOverlays.bind(this)

        window.addEventListener('load', this.handleLoad)
        window.addEventListener('beforeunload', this.handleUnload)
        window.addEventListener('resize', this.handleResize)
        this.$refs.menu.addEventListener('navigation-menu:close', this.handleMenuClose)
        this.$refs.menu.addEventListener('navigation-menu:open', this.handleMenuOpen)

        this.handleScroll('initial-scroll', {
            enter: function (event) {
                this.components.logo.compress()

                if (this.components.slideshow) {
                    this.components.slideshow.hide()
                }

                this.components.hint.hide()
            },
            leave: function (event) {
                if (window.innerWidth > this.MIN_WIDTH_EXPAND_LOGO) {
                    this.components.logo.expand()
                }

                if (this.components.slideshow) {
                    this.components.slideshow.show()
                    this.components.background.update('transparent', 'transparent')
                }
            }
        })

        this.components.hint.hide(true)

        // inizializzazione dimensioni viewport
        this.handleResize()
    }

    ScrollContext.prototype = {
        /**
         * Esecuzione dei task di inizializzazione quando tutti i contenuti (inclusi stili, immagini e script)
         * sono stati caricati. Al termine dell'inizializzazione viene rimosso il loader
         * Se la pagina rimane bloccata sul loader, probabilmente c'è un errore qui o in qualcuno dei metodi
         * che vengono invocati qui, oppure nel costruttore del componente
         * @param {Event} event onload
         */
        handleLoad: function (event) {
            this.registerGradients()
            this.registerPlayers()
            this.registerOverlays()

            this.components.loader.hide()

            if (this.$refs.content.classList.contains('-hint-scroll')) {
                this.components.hint.show()
            }
        },
        /**
         * Gestione della chiusura del menu di navigazione a comparsa
         * In base alla dimensione del viewport potrebbe essere necessario ridurre il logo,
         * e in homepage lo slideshow deve ripartire
         * @param {CustomEvent} event Evento emesso dal container del menu di navigazione
         */
        handleMenuClose: function (event) {
            if (window.innerWidth <= this.MIN_WIDTH_EXPAND_LOGO
                || this.$refs.content.firstElementChild.getBoundingClientRect().top + this.SCROLL_THRESHOLD < 0) {
                this.components.logo.compress()
            }
            else {
                this.components.logo.expand()
            }

            if (this.components.slideshow) {
                this.components.slideshow.play()
            }
        },
        /**
         * Gestione dell'apertura del menu di navigazione a comparsa
         * Con il menu aperto il logo dev'essere semore espanso, e in homepage lo slideshow deve essere fermato
         * @param {CustomEvent} event Evento emesso dal container del menu di navigazione
         */
        handleMenuOpen: function (event) {
            this.components.logo.expand()

            if (this.components.slideshow) {
                this.components.slideshow.stop()
            }
        },
        /**
         * Sotto una certa dimensione del viewport, il logo va ridotto
         * @todo qui andrebbero reinizializzate le Scene ScrollMagic per mantenere consistente
         * il comportamento onscroll delle pagine
         * @param {Event} event onresize
         */
        handleResize: function (event) {
            if (window.innerWidth <= this.MIN_WIDTH_EXPAND_LOGO) {
                this.components.logo.compress()
            }
            else {
                this.components.logo.expand()
            }
        },
        /**
         * Aggiunge una Scene ScrollMagic con i parametri (elemento DOM trigger, durata in pixel, offset
         * dal centro del viewport) passati, e due funzioni di callback per l'ingresso (config.enter, quando
         * l'elemento trigger entra nell'area sensibiler) e l'uscita (config.leave, quando l'elemento trigger
         * esce dall'area sensibile) dalla Scene
         * @param {String} name Nome della Scene, per futuro riferimento
         * @param {Object} config Configurazione della Scene: { triggerElement, duration, offset, enter, leave }
         */
        handleScroll: function (name, config) {
            if (!config) {
                return
            }
            var scene = new ScrollMagic.Scene({
                triggerElement: config.triggerElement,
                duration: isNaN(parseInt(config.duration)) ? 0 : config.duration,
                offset: isNaN(parseInt(config.offset)) ? this.SCROLL_THRESHOLD : parseInt(config.offset)
            })

            if (typeof config.enter == 'function') {
                var onenter =  config.enter.bind(this)
                scene.on('enter', onenter)
            }

            if (typeof config.leave == 'function') {
                var onleave = config.leave.bind(this)
                scene.on('leave', onleave)
            }

            // se si vuole modificare o distruggere una scena, la si ritrova referenziata in
            // this.scenes.name
            this.scenes[name] = scene

            // this.scroll è il Controller ScrollMagic di questo componente
            this.scroll.addScene(scene)
        },
        /**
         * Mostra il loader onbeforeunload
         * @param {Event} event Evento beforeunload
         */
        handleUnload: function (event) {
            this.components.loader.show()
        },
        loadComponent: function(componentName, Constructor) {
            try {
                if (typeof (this.components) === 'undefined') {
                    this.components = {};
                }

                this.components[componentName] = [];
                var elements = document.querySelectorAll('[data-ui-component="' + componentName + '"]');

                Array.prototype.forEach.apply(elements, [function ($el) {
                    this.components[componentName].push(new Constructor($el));
                }.bind(this)]);
            } catch (err) {
                console.warn(err);
            }
            return;
        },
        /**
         * Scene ScrollMagic che modificano i colori delle sfumature di sfondo
         * Se l'elemento trigger contiente un componente IdeaCover, gestisce anche l'animazione
         * di ingresso/uscita della cover
         */
        registerGradients: function () {
            Array.prototype.forEach.apply(this.$refs.gradientTriggers, [function ($trigger, i) {

                var values = $trigger.dataset.gradient ? $trigger.dataset.gradient.split(';') : ['transparent','transparent']
                var cover = $trigger.querySelector('[data-ui-component="idea-cover"]')

                if (cover) {
                    app.components['ideaCover_' + $trigger.id] = new IdeaCover(cover)
                }

                this.handleScroll('gradientTrigger' + i, {
                    triggerElement: $trigger,
                    offset: 0,
                    duration: $trigger.clientHeight,
                    enter: function () {
                        if (cover) {
                            this.components['ideaCover_' + $trigger.id].show()
                        }
                        if (values.length) {
                            this.components.background.update(values[0], values[1])
                        }
                    },
                    leave: function () {
                        if (cover) {
                            app.components['ideaCover_' + $trigger.id].hide()
                        }
                    }
                })
            }.bind(this)])
        },
        /**
         * Scene ScrollMagic che avviano o fermano i video quando questi entrano nel viewport
         * Listener agli eventi dei player per mostrare/nascondere il menu di navigazione in modo
         * che questo non collida con i controlli del player
         */
        registerPlayers: function () {
            this.components.wowzaPlayers.forEach(function (wowza, i) {
                wowza.$el.addEventListener('wowza-video-player:play', function () {
                    app.components.menu.hide()
                }.bind(this))

                wowza.$el.addEventListener('wowza-video-player:pause', function () {
                    app.components.menu.show()
                }.bind(this))

                wowza.$el.addEventListener('wowza-video-player:completed', function () {
                    app.components.menu.show()
                }.bind(this))

                this.handleScroll('wowzaPlayer_' + i, {
                    triggerElement: wowza.$el,
                    offset: 0,
                    duration: window.innerHeight * 0.75,
                    enter: function () {
                        if (typeof wowza.player != 'undefined') {
                            wowza.player.play()
                        }
                    },
                    leave: function () {
                        if (typeof wowza.player != 'undefined') {
                            wowza.player.pause()
                        }
                    }
                })
            }.bind(this))
        },
        registerOverlays: function () {
            var logoBottom = parseInt(window.innerHeight * 0.5 - this.$refs.logo.getBoundingClientRect().bottom)

            Array.prototype.forEach.apply(this.$refs.overlayTriggers, [function ($trigger, i) {

                this.handleScroll('menuTrigger' + i, {
                    triggerElement: $trigger,
                    offset: 0,
                    duration: $trigger.clientHeight,
                    enter: function () {
                        switch ($trigger.dataset.requestOverlay) {
                            case 'off':
                                if (this.components.menu.$refs.button.classList.contains('-dark')) {
                                    this.components.menu.$refs.button.classList.remove('-dark')
                                }
                                break;
                            case 'on':
                            default:
                                if (this.components.menu.$refs.button.classList.contains('-dark')) {
                                    break;
                                }
                                this.components.menu.$refs.button.classList.add('-dark')
                                break;
                        }
                    }
                })

                this.handleScroll('logoTrigger' + i, {
                    triggerElement: $trigger,
                    offset: logoBottom,
                    duration: $trigger.clientHeight,
                    enter: function () {
                        switch ($trigger.dataset.requestOverlay) {
                            case 'off':
                                this.components.logo.whiten()
                                break;
                            case 'on':
                            default:
                                this.components.logo.darken()
                                break;
                        }
                    }
                })

                Array.prototype.forEach.apply(this.$refs.socialIcons, [function ($icon, j) {
                    this.handleScroll('social' + j + 'trigger' + i, {
                        triggerElement: $trigger,
                        offset: parseInt(window.innerHeight * 0.5 - $icon.getBoundingClientRect().bottom),
                        duration: $trigger.clientHeight,
                        enter: function () {
                            switch ($trigger.dataset.requestOverlay) {
                                case 'off':
                                    if ($icon.classList.contains('-dark')) {
                                        $icon.classList.remove('-dark')
                                    }
                                    break;
                                case 'on':
                                default:
                                    if ($icon.classList.contains('-dark')) {
                                        break;
                                    }
                                    $icon.classList.add('-dark')
                                    break;
                            }
                        }
                    })
                }.bind(this)])
            }.bind(this)])
        }
    }

    return ScrollContext
}));
