(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('AnimatedLogo', [], factory);
    } else {
        // Browser globals
        root.AnimatedLogo = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function AnimatedLogo(element) {
        this.$el = element
        this.$refs = {}
        this.$refs.transitions = this.$el.querySelectorAll('[data-transition]')

        this.compress = this.compress.bind(this)
        this.expand = this.expand.bind(this)
        this.darken = this.darken.bind(this)
        this.whiten = this.whiten.bind(this)
    }


    AnimatedLogo.prototype = {
        compress: function () {
            if (this.$el.classList.contains('-compressed')) {
                return
            }

            this.$el.classList.add('-compressed')

            Array.prototype.forEach.apply(this.$refs.transitions, [function ($el) {
                var animationName = $el.dataset.transition || 'v'
                $el.addEventListener('animationstart', function (event) {
                    event.stopPropagation()
                    event.target.classList.add(animationName + '-leave-active')
                }.bind(this))

                $el.addEventListener('animationend', function (event) {
                    event.stopPropagation()
                    event.target.classList.remove(animationName + '-enter')
                    event.target.classList.remove(animationName + '-enter-active')
                    event.target.classList.remove(animationName + '-leave-active')
                    event.target.classList.remove(animationName + '-leave')

                    if (this.$el.classList.contains('-compressed')) {
                        event.target.style.display = 'none'
                    }
                }.bind(this))
                $el.classList.add(animationName + '-leave')
            }.bind(this)])
        },
        expand: function () {
            if (false ==    this.$el.classList.contains('-compressed')) {
                return
            }

            this.$el.classList.remove('-compressed')

            Array.prototype.forEach.apply(this.$refs.transitions, [function ($el) {
                var animationName = $el.dataset.transition || 'v'
                $el.style.display = null
                $el.addEventListener('animationstart', function (event) {
                    event.stopPropagation()
                    event.target.classList.add(animationName + '-enter-active')
                }.bind(this))

                $el.addEventListener('animationend', function (event) {
                    event.stopPropagation()
                    event.target.classList.remove(animationName + '-enter')
                    event.target.classList.remove(animationName + '-enter-active')
                    event.target.classList.remove(animationName + '-leave-active')
                    event.target.classList.remove(animationName + '-leave')

                    if (this.$el.classList.contains('-compressed')) {
                        event.target.style.display = 'none'
                    }
                }.bind(this))
                $el.classList.add(animationName + '-enter')
            }.bind(this)])
        },
        darken: function () {
            if (this.$el.classList.contains('-dark')) {
                return
            }

            this.$el.classList.add('-dark')
        },
        whiten: function () {
            if (this.$el.classList.contains('-dark')) {
                this.$el.classList.remove('-dark')
            }
        }
    }

    return AnimatedLogo
}));