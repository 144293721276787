(function () {
    'use strict';

    if (document.readyState === 'interactive') {
        setup();
    } else {
        document.addEventListener('DOMContentLoaded', setup);
    }

    /**
     * Esegue il setup quando il DOM � caricato in memoria
     */
    function setup() {
        // commons/lib/load-font.js                     caricamento webfont con WebFontLoader
        loadFont();
        // commons/lib/browser-check.js                 applica classi CSS ai componenti in base al browser corrente
        browserCheck();
        // commons/lib/gdpr-cookie-notice-setup.js      configura il banner GDPR
        // gdprCookieNoticeSetup();

        // commons/src/components/scroll-context.js     componente master del front-end
        window.app = new ScrollContext(document.querySelector('.scroll-context'), {
            MIN_WIDTH_EXPAND_LOGO: 960,
            SCROLL_THRESHOLD: 50
        });

        var titles = document.querySelectorAll('.idea-title');

        Array.prototype.forEach.apply(titles, [function ($el) {
            var link = $el.querySelector('a[href]');
            if (link) {
                $el.addEventListener('click', function (event) {
                    var href = link.getAttribute('href');
                    window.location.href = href;
                });
                $el.style.cursor = 'pointer';
            }

        }]);
    }
})();