(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('AnimatedSlide', ['lottie'], factory);
    } else {
        // Browser globals
        root.AnimatedSlide = factory(root.lottie);
    }
}(typeof self !== 'undefined' ? self : this, function (lottie) {
    'use strict';

    function __enter(animationName, $el) {
        return new Promise(function (resolve) {
            animationName = animationName || 'v'

            if ($el == null) {
                return resolve()
            }

            if ($el.style.display != 'none') {
                resolve();
            }

            $el.addEventListener('animationend', function (event) {
                event.stopPropagation()
                event.target.classList.remove(animationName + '-leave')
                event.target.classList.remove(animationName + '-leave-active')
                event.target.classList.remove(animationName + '-enter')
                event.target.classList.remove(animationName + '-enter-active')
                resolve()
            }, { once: true })

            $el.style.display = null;
            $el.classList.add(animationName + '-enter')
            $el.classList.add(animationName + '-enter-active')
        })
    }

    function __leave(animationName, $el) {
        return new Promise(function (resolve) {
            animationName = animationName || 'v'

            if ($el == null) {
                return resolve()
            }

            if ($el.style.display == 'none') {
                resolve();
            }

            $el.addEventListener('animationend', function (event) {
                event.stopPropagation()
                event.target.style.display = 'none'
                event.target.classList.remove(animationName + '-leave')
                event.target.classList.remove(animationName + '-leave-active')
                event.target.classList.remove(animationName + '-enter')
                event.target.classList.remove(animationName + '-enter-active')
                resolve()
            }, { once: true })

            $el.classList.add(animationName + '-leave')
            $el.classList.add(animationName + '-leave-active')
        })
    }

    function AnimatedSlide(element) {
        this.$el = element
        this.$refs = {
            canvas: this.$el.querySelector('[data-animation]'),
            text: Array.from(this.$el.querySelectorAll('h1 > span')),
            link: this.$el.querySelector('h1 > a'),
            ticker: this.$el.querySelector('h1 > a > .ticker'),
        }

        this.loop = this.$refs.canvas.dataset.loop

        this.createAnimation = this.createAnimation.bind(this)
        this.dispatch = this.dispatch.bind(this)
        this.enter = this.enter.bind(this)
        this.leave = this.leave.bind(this)
        this.loadAnimation = this.loadAnimation.bind(this)
        this.handleError = this.handleError.bind(this)
        this.reset = this.reset.bind(this)

        this.loadAnimation().then(function (data) {
            this.createAnimation(data)
            this.dispatch('animated-slide:ready', {
                bubbles: true,
                cancelable: false,
                detail: {
                    instance: this,
                    animation: this.animation,
                }
            })

            this.animation.addEventListener('enterFrame', function (event) {
                var completed = Math.round(event.currentTime) / event.totalTime
                if (this.$refs.ticker) {
                    this.$refs.ticker.style.width = Math.round(completed * 100) + '%'
                }
            }.bind(this))

            this.animation.addEventListener('complete', function (event) {
                this.leave()
            }.bind(this))
        }.bind(this)).catch(this.handleError)

        this.reset()
    }


    AnimatedSlide.prototype = {
        createAnimation: function (data) {
            this.animation = lottie.loadAnimation({
                container: this.$refs.canvas,
                renderer: 'svg',
                loop: this.loop,
                autoplay: false,
                animationData: data
            })
        },
        loadAnimation: function () {
            return new Promise(function (resolve, reject) {
                fetch(this.$refs.canvas.dataset.animation).then(function (response) {
                    return response.json()
                }.bind(this)).then(function (data) {
                    resolve(data)
                }.bind(this)).catch(function (error) {
                    reject(error)
                }.bind(this))
            }.bind(this))
        },
        handleError: function (error) {
            this.$el.classList.add('-error')
            console.warn(this, error)
        },
        enter: function () {
            __enter('fade-zoom', this.$refs.link)
            this.$refs.text.forEach(function ($el) { __enter('fade-blur', $el) }.bind(this))

            this.animation.play()
        },
        leave: function () {
            var transitions = [ __leave('fade-zoom', this.$refs.link)]
            for (var i = 0; i < this.$refs.text.length; i++) {
                transitions.push(__leave('fade-blur', this.$refs.text[i]))
            }

            Promise.all(transitions).then(function () {
                this.reset()
                this.dispatch('animated-slide:done', {
                    bubbles: true,
                    cancelable: false,
                    detail: {
                        instance: this,
                        animation: this.animation,
                    }
                })
            }.bind(this))
        },
        reset: function () {
            this.$refs.text.forEach(function ($el) { $el.style.display = 'none' })

            if (this.$refs.link) {
                this.$refs.link.style.display = 'none'
            }

            if (this.$refs.ticker) {
                this.$refs.ticker.style.width = '0%'
            }

            if (this.animation && typeof this.animation.stop == 'function') {
                this.animation.stop()
            }
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.$el.dispatchEvent(newEvent);
        },
    }

    return AnimatedSlide
}));