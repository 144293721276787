(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('IdeaCover', [], factory);
    } else {
        // Browser globals
        root.IdeaCover = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    /**
     * @private
     * @description Genera il valore dell'attributo "d" (https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d)
     * del tracciato utilizzato come maschera di ritaglio per tutti gli elementi della copertina
     * @param {Number} size dimensione in px del quadrato di partenza
     * @param {Number} ratio (min 0.05, max 1) proporzione della maschera di ritaglio
     * @param {Number} offsetX distanza orizzontale in px dall'angolo in alto a sinistra della pagina
     * @param {Number} offsetY distanza verticale in px dall'angolo in alto a sinistra della pagina
     * @returns {String} valore dell'attributo "d"
     */
    function clippingMaskPath(size, ratio, offsetX, offsetY) {
        /*
         * Tra le estremità  (a-b,b-c,d-e,e-f) dei segmenti "corner" vengono disegnati due semiarchi
         * utilizzando il comando "curva ellittica" (A) dell'attributo svg "d"
         *
         * I restanti punti (c-d,f-a) vengono connessi utilizzando il comando "linea retta" (L)
         * e "chiudi tracciato" (z)
         *
         *        0______a___b
         * edge   |      |   | corner
         *        |      |___c
         *        f___       |
         * corner |   |      |
         *        e___d______|
         *            size
         *
         * Nel caso particolare in cui "corner" == "size" (ratio == 1), il risultato è un cerchio
         * circoscritto al quadrato di partenza (stato finale dell'animazione di entrata della Case History)
         */

        size = parseInt(size)

        ratio = Math.min(Math.max(ratio, 0.05), 1)

        var corner = size * ratio
        var radius = corner / 2 * Math.SQRT2
        var edge = size - corner
        // compensa la curvatura dei bordi
        var offset = clippingMaskPadding(size, ratio)
        offsetX += offset;
        offsetY += offset;

        // BEHOLD THE UNFATHOMABLE POWER OF SCALABLE VECTOR GRAPHICS
        return 'M' + (edge + offsetX) + ',' + offsetY
            + ' A' + radius + ',' + radius + ' 0,0,1 ' + (size + offsetX) + ',' + offsetY
            + ' A' + radius + ',' + radius + ' 0,0,1 ' + (size + offsetX) + ',' + (offsetY + corner)
            + ' L' + (offsetX + corner) + ',' + (offsetY + size)
            + ' A' + radius + ',' + radius + ' 0,0,1 ' + offsetX + ',' + (offsetY + size)
            + ' A' + radius + ',' + radius + ' 0,0,1 ' + offsetX + ',' + (offsetY + edge)
            + 'z'
    }

    /**
     * @private
     * @description Calcola, data la dimensione del quadrato di partenza della
     * maschera di ritaglio, una distanza che compensa la curvatura dei bordi
     * e che corrisponde alla differenza tra il raggio dell'estremità arrotondata
     * della maschera e il segmento del quadrato utilizzato per costruire la stessa
     * estremità arrotondata
     */
    function clippingMaskPadding(size, ratio) {

        var corner = size * ratio
        var radius = corner / 2 * Math.SQRT2
        var offset = (corner / 2 - radius) * -1
        return offset
    }

    var CLIPPING_MASK_RATIO = 4 / 9

    function IdeaCover(element) {
        this.$el = element

        this.$refs = {
            clipPath: this.$el.querySelector('clipPath > path')
        }

        this.$el.style.display = 'none'

        // this.clip(700, 190)
    }

    IdeaCover.prototype = {
        clip: function (size, offset) {
            this.$refs.clipPath.setAttribute('d', clippingMaskPath(size, CLIPPING_MASK_RATIO, offset, offset))
        },
        show: function () {
            this.$el.addEventListener('animationend', function (event) {
                event.target.classList.remove('idea-enter')
                event.target.classList.remove('idea-leave')
            }.bind(this), { once: true })
            this.$el.style.display = null
            this.$el.classList.add('idea-enter')
        },
        hide: function () {
            this.$el.addEventListener('animationend', function (event) {
                event.target.classList.remove('idea-enter')
                event.target.classList.remove('idea-leave')
                event.target.style.display = 'none'
            }.bind(this), { once: true })
            this.$el.classList.add('idea-leave')
        }
    }

    return IdeaCover
}));