(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ScrollHint', [], factory);
    } else {
        // Browser globals
        root.ScrollHint = factory();
    }
}(typeof self !== 'undefined' ? self : this, function (WowzaPlayer) {
    'use strict';

    function Hint(element) {
        this.$el = element
    }

    Hint.prototype = {
        show: function (skipAnimation) {
            if (this.$el.style.display != 'none') {
                this.$el.classList.remove('show-hint-enter')
                this.$el.classList.remove('show-hint-leave')
                return
            }

            if (skipAnimation) {
                this.$el.style.display = null
            } else {
                this.$el.addEventListener('animationend', function (event) {
                    event.stopPropagation()
                    event.target.classList.remove('show-hint-enter')
                    event.target.classList.remove('show-hint-leave')
                }.bind(this), { once: true })
                this.$el.classList.add('show-hint-enter')
                this.$el.style.display = null
            }
        },
        hide: function (skipAnimation) {
            if (this.$el.style.display == 'none') {
                this.$el.classList.remove('show-hint-enter')
                this.$el.classList.remove('show-hint-leave')
                return
            }

            if (skipAnimation) {
                this.$el.style.display = 'none'
            } else {
                this.$el.addEventListener('animationend', function (event) {
                    event.stopPropagation()
                    event.target.classList.remove('show-hint-enter')
                    event.target.classList.remove('show-hint-leave')
                    event.target.style.display = 'none'
                }.bind(this), { once: true })
                this.$el.classList.add('show-hint-leave')
            }
        },
    }

    return Hint

}));
